<template>
  <simple :show-overlay="showOverlay">
    <alert :key="componentKey" :flash="$page.props.flash" class="!absolute bottom-5 left-8 w-8/12 lg:w-auto" />
    <!-- SHOW SEARCH BAR IF WE HAVE NO RESULTS TO SHOW -->
    <div
      v-if="searchResults === null"
      class="flex-1 flex flex-col items-center w-full md:w-10/12 xl:w-9/12 md:absolute md:absolute-center-x mx-auto md:top-[14vh] lg:top-[16vh] xl:top-[22vh] 2xl:top-[30vh]">
      <div v-if="headingText || subHeadingText" class="mb-12 mt-12">
        <h1 class="bo:font-graphik-bold hj:font-druk-heavy-italic hj:text-f-white text-3xl text-center mb-2">
          {{ headingText }}
        </h1>
        <p class="text-f-bg bo:text-f-tertiary hj:text-f-white text-center">{{ subHeadingText }}</p>
      </div>
      <div
        class="flex-1 flex flex-col justify-center gap-4 md:border-2 md:hj:border-f-white md:bo:border-f-tertiary/[.07] md:rounded-xl p-6 h-full">
        <SearchBar
          :activities="form_data.activities"
          :venues="form_data.venues"
          :reservation-id="reservation_id"
          :is-widget="isWidget"
          :code="code" />
        <p v-if="!form_data.hide_booking_link" class="text-center text-f-white bo:text-f-tertiary mt-4">
          {{ enquiryGuestCount }} or more guests, or just want to pay a deposit today? Complete our
          <Link
            :href="route(this.getRoute('booking.enquire.index'), enquiryFormParams)"
            class="text-f-contrast bo:font-graphik-medium hj:font-lausanne-regular hj:font-bold underline cursor-pointer"
            >enquiry form</Link
          >
          and we'll get back to you. <br />
          <!-- <br />We are unable to accept credit card payments online due to The Gambling Act (2005) -->
        </p>
      </div>
    </div>

    <SavingMessage :loading="loading"></SavingMessage>

    <!-- IF WE HAVE RESULTS, CHOOSE THE APPROPRIATE COMPONENT TO DISPLAY -->
    <ChooseAlternative
      v-if="shouldShow('ChooseAlternative')"
      :reservation-id="reservation_id"
      :show-fully-booked-times="false"
      @show-overlay="showOverlay = true"
      @hide-overlay="showOverlay = false"
      @skip-select-package="selectDefaultPackageAndRedirect" />
    <Calendar
      v-if="shouldShow('Calendar')"
      :reservation-id="reservation_id"
      :show-fully-booked-times="false"
      @show-overlay="showOverlay = true"
      @hide-overlay="showOverlay = false"
      @skip-select-package="selectDefaultPackageAndRedirect" />
    <SelectPackage
      v-if="shouldShow('SelectPackage')"
      :reservation-id="reservation_id"
      @show-overlay="showOverlay = true"
      @hide-overlay="showOverlay = false" />
    <NoResults v-if="shouldShow('NoResults')" />
  </simple>
</template>

<script>
import { Link } from "@inertiajs/vue3"
import _ from "lodash"
import { DateTime } from "luxon"
import Alert from "../../components/Alert.vue"
import Calendar from "../../components/Calendar/Calendar.vue"
import ChooseAlternative from "../../components/ChooseAlternative.vue"
import LoadingSpinner from "../../components/LoadingSpinner.vue"
import NoResults from "../../components/NoResults.vue"
import SearchBar from "../../components/SearchBar.vue"
import SelectPackage from "../../components/SelectPackage.vue"
import Simple from "../../layouts/Simple.vue"
import SavingMessage from "../../components/SavingMessage.vue"

export default {
  name: "Index",
  components: {
    SavingMessage,
    LoadingSpinner,
    Alert,
    NoResults,
    SelectPackage,
    ChooseAlternative,
    Calendar,
    Simple,
    SearchBar,
    Link,
  },
  props: {
    form_data: {
      type: Object,
      required: true,
    },
    code: {
      required: false,
      type: String,
    },
    venue: {
      type: [Object, String],
    },
    date: {
      type: String,
    },
    time: {
      type: String,
    },
    mode: {
      type: String,
    },
    day: {
      type: String,
    },
    month: {
      type: String,
    },
    guests: {
      type: [Number, String],
    },
    activity: {
      type: [Object, String],
    },
    reservation_id: {
      type: String,
    },
    results: {
      type: [Array, Object],
    },
    widget: {
      type: String,
    },
    next_page: {
      type: String,
    },
  },
  data() {
    return {
      componentKey: 0,
      showOverlay: false,
      loading: false,
    }
  },
  computed: {
    searchResults() {
      return this.$store.state.searchResults
    },
    nextStep() {
      return this.$store.state.searchNextStep
    },
    nextPage() {
      return this.$store.state.searchNextPage
    },
    searchParams() {
      return this.$store.state.searchParams
    },
    selectedBookingSlot() {
      return this.$store.state.selectedBookingSlot
    },
    bookingData() {
      if (this.selectedBookingSlot) {
        return {
          date: DateTime.fromFormat(this.selectedBookingSlot.date, "EEE, dd MMM yyyy").toFormat("yyyy-MM-dd"),
          time: this.selectedBookingSlot.time,
          guests: this.selectedBookingSlot.guests,
          venue_id: this.selectedBookingSlot.venue.id,
          activity_id: this.selectedBookingSlot.activity.id,
        }
      }
      const when = DateTime.fromFormat(this.searchParams.when.value, "yyyy-MM-dd HH:mm")
      return {
        date: when.toFormat("yyyy-MM-dd"),
        time: when.toFormat("HH:mm"),
        guests: this.searchParams.guests,
        venue_id: this.searchParams.venue.id,
        activity_id: this.searchParams.activity.id,
      }
    },
    isWidget() {
      return this.widget === "1"
    },
    enquiryFormParams() {
      const params = {}

      if (
        this.$page.props.brand_settings?.enquiry_form_override &&
        this.$page.props.brand_settings?.enquiry_form_override !== "No override"
      ) {
        params.enquiryOverride = this.$page.props.brand_settings.enquiry_form_override
      }
      if (this.form_data.venues.length === 1) {
        params.venue_id = this.form_data.venues[0].id
      }
      return params
    },
    headingText() {
      return this.form_data?.heading
    },
    subHeadingText() {
      return this.form_data?.sub_heading
    },
    enquiryGuestCount() {
      return Math.min(this.form_data.self_service_cut_off, this.form_data.to_guests + 1)
    },
  },
  methods: {
    updateNextStep(nextStep) {
      this.nextStep = nextStep
    },
    shouldShow(component) {
      if (this.nextStep !== null) {
        if (this.nextStep !== "select_package") {
          return this.nextStep === _.snakeCase(component)
        } else {
          return this.nextPage === null && this.nextStep === _.snakeCase(component)
        }
      }
      //return this.searchResults !== null && this.searchResults.hasOwnProperty('next_step') && this.searchResults.next_step === _.snakeCase(component)
    },
    setSearchParamsFromGetRequest() {
      let updatedParams = false
      const params = {
        venue: {
          id: null,
          name: null,
          slug: null,
        },
        when: {
          value: null,
          display: null,
          mode: "date",
          day: null,
          month: null,
        },
        guests: null,
        activity: {
          id: null,
          name: null,
          slug: null,
        },
      }
      if (this.$props.code) {
        updatedParams = true
        params.code = this.$props.code
      }
      if (this.venue) {
        updatedParams = true
        const venue = this.form_data.venues.find((v) => v.slug === this.venue)
        params.venue = {
          id: venue.id,
          name: venue.name,
          slug: venue.slug,
        }
      }
      if (this.activity) {
        updatedParams = true
        const activity = this.form_data.activities.find((a) => a.slug === this.activity)
        params.activity = {
          id: activity.id,
          name: activity.name,
          slug: activity.slug,
        }
      }
      if (this.date && this.time) {
        updatedParams = true
        const whenDisplay = DateTime.fromFormat(this.date + " " + this.time, "yyyy-MM-dd HH:mm").toFormat(
          "EEE, dd MMM - HH:mm",
        )
        params.when = {
          value: this.date + " " + this.time,
          display: whenDisplay,
          mode: "date",
          day: null,
          month: null,
        }
      }
      if (this.mode && this.month && this.day) {
        const whenDisplay = this.capitalise(this.day) + " in " + DateTime.fromISO(this.month).toFormat("MMMM")
        params.when = {
          value: null,
          display: whenDisplay,
          mode: "flexible",
          day: this.day,
          month: this.month,
        }
      }
      if (this.guests) {
        updatedParams = true
        params.guests = this.guests
      }

      if (updatedParams) {
        this.$store.dispatch("updateSearchParams", params)
        this.$store.dispatch("updateSearchParamsForCurrentSearch", params)
      }
    },
    selectDefaultPackageAndRedirect() {
      if (
        this.nextStep === "select_package" &&
        this.nextPage !== null &&
        this.$store.state.searchFormData.packages.length === 1
      ) {
        this.showOverlay = true
        this.loading = true
        const packageItem = this.$store.state.searchFormData.packages[0]
        axios.post(route(this.getRoute("pending_change.update"), this.reservation_id), this.bookingData).then((r) => {
          if (r.data.success) {
            axios
              .post(route(this.getRoute("reservation.select_package"), this.reservation_id), {
                package_id: packageItem.id,
              })
              .then((r) => {
                if (r.data.success) {
                  this.showOverlay = false
                  this.loading = false
                  this.trackComplete(
                    "select_package",
                    {
                      location: window.location.href,
                      package_name: packageItem.name,
                    },
                    this.trackingGroups.all,
                  )

                  this.$inertia.get(route(this.getRoute("reservation.show_payment"), this.reservation_id))
                }
              })
              .catch((err) => {
                console.log(err)
                this.loading = false
                this.showOverlay = false
                this.$emit("hideOverlay")
                window.scrollTo(0, 0)
                this.errorMessage = {
                  type: "error",
                  message: err.response.data.errors?.[0],
                }
                this.alertKey++
              })
          }
        })
      }
    },
    fireTracking() {
      const eventData = {
        event: "search",
        properties: {
          location: window.location.href,
          venue: this.searchParams?.venue.name,
          date:
            this.searchParams.when.value !== null
              ? this.getDateTime(this.searchParams.when.value, "yyyy-MM-dd HH:mm").toFormat("yyyy-MM-dd")
              : null,
          time:
            this.searchParams.when.value !== null
              ? this.getDateTime(this.searchParams.when.value, "yyyy-MM-dd HH:mm").toFormat("HH:mm")
              : null,
          guests: this.searchParams.guests,
          activity: this.searchParams?.activity.name,
          next_step: this.searchResults?.next_step,
          searchoverride: this.code,
          flexible_date: this.searchParams.when.day,
          flexible_month: this.searchParams.when.month,
        },
        trackingServices: this.trackingGroups.all,
      }

      if (!this.results) {
        this.trackView(
          "search",
          {
            location: window.location.href,
            searchoverride: this.code,
          },
          this.trackingGroups.all,
        )
      } else {
        this.trackComplete(eventData.event, eventData.properties, eventData.trackingServices, this.isWidget)
      }
    },
  },
  mounted() {
    this.selectDefaultPackageAndRedirect()
    this.fireTracking()
  },
  beforeUnmount() {
    //this.$store.dispatch('updateSearchResults', null)
    //this.$store.dispatch('updateSearchNextStep', null)
  },
  created() {
    this.setSearchParamsFromGetRequest()

    this.$store.dispatch("updateSearchFormData", this.form_data)
    this.$store.dispatch("updateSearchChanged", false)
    if (this.results) {
      this.$store.dispatch("updateSearchResults", this.results)
      this.$store.dispatch("updateSearchNextPage", this?.next_page)
      if (this.$store.state.updateNextStep) {
        this.$store.dispatch("updateSearchNextStep", this.results?.next_step)
      }
    }
  },
}
</script>

<style scoped></style>
